<template>
  <div id="app" data-server-rendered="true">    
    <div>
      <StyleConfigure/>
      <router-view/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import i18n from "@/i18n"; 
import StyleConfigure from "./components/base/StyleConfigure";
import unleash from "@/tools/unleash";

export default {
  name: "App",
  components: {StyleConfigure},
  mixins: [],
  methods: {
    setDocAtr(data){
      
      if (data.title){
        document.title = data.title
      }
      if (data.lang) {
        const htmlDoc = document.documentElement // returns the html tag
        htmlDoc.setAttribute('lang', data.lang)
      }      
      if (data.metaTags) {
        const descriptionElem = document.querySelector('head meta[name="description"]');
        descriptionElem.setAttribute('content', data.metaTags.description);

        const keywordsElem = document.querySelector('head meta[name="keywords"]');
        keywordsElem.setAttribute('content', data.metaTags.keywords);

        const ogTitleElem = document.querySelector('head meta[property="og:title"]');
        ogTitleElem.setAttribute('content', data.title);

        const ogDescriptionElem = document.querySelector('head meta[property="og:description"]');
        ogDescriptionElem.setAttribute('content', data.metaTags.description);
      }  
    }
  },
  computed: {
    ...mapGetters('application', ['isShowPreloader', "config"])
  },
  mounted() {
    unleash.start();
    unleash.on('update', () => {
      let toggles = unleash.getAllToggles()
      //this.initFeatureFlags(toggles)
    });
    this.setDocAtr( this.config)

  },
  watch: {
    config(data) {
      this.setDocAtr(data)
    },  
    "$route.params.lang"() {
        if (this.$route.params.lang) {
          let lang = this.$route.params.lang
          i18n.locale = lang;
          this.$store.commit('application/setLang', lang);
          this.setDocAtr(this.config)
        } 
    },  
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";

#soft-preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  background: #0000004a;
  padding: 50% 50%;
}
</style>
