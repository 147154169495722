import moment from "moment";

export const stripTags = (html) => {
    return html.replace(/(<([^>]+)>)/gi, "").trim()
}

export const hexToRgb = (hex) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const languageConfig = () => {
    let availableLanguages = ['ru', 'en', 'de']
    let config = {
        locale: {
            'ru': {
                id: 'ru',
                lang: 'ru_RU',
                langDashes: 'ru-RU',
                weekday: 1,
            },
            'en': {
                id: 'en',
                lang: 'en_US',
                langDashes: 'en-US',
                weekday: 0,
            },
            'de': {
                id: 'de',
                lang: 'de_DE',
                langDashes: 'de-DE',
                weekday: 1,
            },
        },
        preferLang: ['ru'],
        fallbacks: [],
    }

    for (let j = 0; j < availableLanguages.length; j++) {
        if (navigator.language.toLowerCase().includes(availableLanguages[j])) {
            config.preferLang = availableLanguages[j]
        } else {
            for (let i = 0; i < navigator.languages.length; i++) {
                if (navigator.languages[i].toLowerCase().includes(availableLanguages[j])) {
                    config.fallbacks.push(availableLanguages[j])
                }
            }
        }
    }
    moment.locale(config.preferLang)
    return config
}

export const itemImagePath = (item, size) => {
    let str = '',
        img

    if (item.file_id) {
        img = item
    } else if(item.foto) {
        img = item.foto
    } else {
        img = item
    }
    
    if (img.image_url_thumb) {
        str = img.image_url_thumb
    } else if (img.image_url) {
        str = img.image_url
    } else {
        return null
    }

    str = str.replace('ik.imagekit.io/lunchpad', 'proxy-img.lunchpad.ru/lunchpad')
    if (size) {
        str += '/tr:'
        let sizes = []
        if (size?.w) {
            sizes.push(`w-${size.w}`)
        }
        if (size?.h) {
            sizes.push(`h-${size.h}`)
        }
        if (typeof size === "string") {
            sizes.push(`w-${size}`)
        }

        str += sizes.join(',')
    }

    return str
}
