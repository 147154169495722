<template>
  <component :is="'style'">
    .lp-primary-bg {
      background: {{colors.primary.color}} !important;
      color: {{colors.primary.font}} !important;
    }
    .lp-primary-shadow {
      box-shadow: 0px 2px 20px {{shadowColor(colors.primary.color, 74)}} !important;
    }
    .lp-secondary-bg {
      background: {{colors.secondary.color}} !important;
      color: {{colors.secondary.font}} !important;
    }
    .lp-secondary-shadow {
      box-shadow: 0px 2px 15px {{shadowColor(colors.secondary.color, 15)}} !important;
    }
    .nav-item.active {
      background: {{colors.secondary.color}} !important;
      color: {{colors.secondary.font}} !important;
    }    
    .modal-footer .btn.btn-warning {
      background: {{colors.primary.color}} !important;
      border-color: {{colors.primary.color}} !important;
      color: {{colors.primary.font}} !important;
      box-shadow: 0px 2px 20px {{shadowColor(colors.primary.color, 74)}} !important;
    }
    

  </component>
</template>

<script>
import fontColorContrast from 'font-color-contrast'
import {hexToRgb} from "../../helpers/functions";

export default {
  name: "StyleConfigure",
  methods: {
    shadowColor(color, opacity) {
      let rgb = hexToRgb(color)
      return rgb ? `rgb(${rgb.r} ${rgb.g} ${rgb.b} / ${opacity}%)` : null
    }
  },
  computed: {
    
    colors(){

      let primaryColor = '#fdc639'
      let secondaryColor = '#333'
      return {  primary: {color: primaryColor,
                          font: fontColorContrast(primaryColor)},
                secondary: {color: secondaryColor,
                          font:fontColorContrast(secondaryColor)}}
    },
     stylesTolLevelCategories() {
      let a = false;
      for (let i = 0; i < this.topLevelCategories.length; i++) {
        if (this.topLevelCategories[i].items.length !== 0) {
          for (let j = 0; j < this.topLevelCategories[i].items.length; j++) {
            if (this.topLevelCategories[i].items[j].type === 0) {
              a = true;
              return a;
            }
          }
        }
      }
      return a;
    },
    ColorTitle() {
      return {color: '#fff'};
    }
  }
}
</script>

<style scoped>

</style>
